import { merge } from "theme-ui"
import originalTheme from "@lekoarts/gatsby-theme-jodie/src/gatsby-plugin-theme-ui"

const contentStyles = {
  px: [3, 3, 4, 5, 6],
  py: [3, 3, 4, 5, 5],
  maxWidth: `96rem`,
  margin: `0 auto`,
}

const theme = merge(originalTheme, {
  fonts: {
    body: `"Work Sans", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  content: {
    page: {
      ...contentStyles,
    },
    custom: {
      margin: 0,
      padding: 0,
      img: {
        maxWidth: `100%`,
      },
    },
    project: {
      ...contentStyles,
      maxWidth: `6xl`,
    },
    imageList: {
      ...contentStyles,
      ".gatsby-image-wrapper:not(:last-child)": {
        marginBottom: `2rem`,
      },
    },
  },
})

export default theme
